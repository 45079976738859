<template>
  <div>
    <pm-Card>
      <template v-slot:title>
        Endereço e Transporte
      </template>
      <template v-slot:subtitle>
        Insira os dados abaixo
      </template>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-1 cep_responsive">
            <label for="cep">CEP:</label>
            <pm-InputMask  mask="99.999-999"  v-model="cepInfo"  placeholder="99.999-999" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="logradouro">Logradouro:</label>
            <pm-InputText id="logradouro" v-model="info.logradouro"/>
          </div>
          <div class="field col-12 md:col-1 num_responsive">
            <label for="firstname">Número:</label>
            <pm-InputText id="numero" v-model="info.numero"/>
          </div>
          <div class="field col-12 md:col-2">
            <label for="firstname">Complemento:</label>
            <pm-InputText id="complemento" v-model="info.complemento"/>
          </div>
          <div class="field col-12 md:col-2" >
            <label for="firstname">Bairro/Distrito:</label>
            <pm-InputText id="bairro" v-model="info.bairro"/>
          </div>
          <div class="field col-12 md:col-2" >
            <label for="firstname">Cidade:</label>
            <pm-InputText id="cidade" v-model="info.cidade"/>
          </div>
          <div class="field col-12 md:col-1 estado_responsive" >
            <label for="firstname">Estado:</label>
            <pm-InputText id="estado" v-model="info.estado"/>
          </div>
          <div class="field col-12 md:col-12" ></div>
          <div class="row field col-12 md:col-12">
            <div class="field-radiobutton" style="margin-top:24px;" >
              <label>Utiliza transporte?</label>&nbsp;
              <input type="radio" name="sim" value="1" v-model="info.utiliza_transporte" />
              <label for="sim">Sim</label>
              <input type="radio" name="nao" value="0" v-model="info.utiliza_transporte" style="margin-left:7px;" />
              <label for="nao">Não</label>
              <!--<pm-RadioButton  name="sim" value="1" v-model="info.utiliza_transporte"  />
                            <label for="sim">Sim</label>

                            <pm-RadioButton  name="nao" value="0" v-model="info.utiliza_transporte" style="margin-left:7px;"/>
                            <label for="nao">Não</label>
                            -->
            </div>
            <div  v-if="info.utiliza_transporte == 1">
              <label >Qual transporte?</label>

              <pm-Dropdown :style="errorColor" v-model="info.qual_transporte" :options="selecTransportes" />
              <small v-show="validationErrors.qual_transporte && submitted" class="p-error">Informe o transporte utilizado!</small>

              <!--<pm-InputText id="estado" v-model="info.qual_transporte" :class="{'p-invalid': validationErrors.qual_transporte && submitted}" />
                            -->

            </div>
          </div>

        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
          <pm-Button label="Próximo" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
        </div>
      </template>
    </pm-Card>
  </div>
</template>


<script>
import { buscarCEP } from "@/class/buscarCEP";

export default {
    props: {
      dadosPessoa: { type: Array, default: () => [] },
      pg:{},
    },
    data () {
        return {
          selecTransportes:['Ônibus', 'Van / Kombi', 'Carro', 'Bicicleta', 'Outros'],
            info: {
                nome: '',
                nomeMae: '',
                nomePai: '',
                possuiDeficiencia: false,
                deficiencia: '',
                nee:false,
                observacao:'',
                email:'',
                senha:'',
                estadocivil:'Solteiro',
                certidaoCasamento:'',
                certidaoNascimento:'',
                rg:'',
                dataEmissao:'',
                cpf:'',
                dataNascimento:'',
                sexo:'',
                cep:'',
                logradouro:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                telefone:[],
                utiliza_transporte:0,
                qual_transporte:'',
                escola_id:0,
            },
            cepInfo:'',
            submitted: false,
            validationErrors: {}
        }
    },
    watch: {
        cepInfo() {
            this.info.cep = this.cepInfo;
            const cepTest = this.cepInfo.replace(".", "").replace("-", "").replace("_", "");
            if (cepTest.length == 8) {
                this.BuscarCEPFunction(cepTest)
            }
        }
    },
    methods: {
        async BuscarCEPFunction(cepTest) {
            try {
                const data = await buscarCEP.searchCep(cepTest);
                this.info.estado = (data['uf'] != '' ? data['uf'] : this.info.estado),
                this.info.cidade =  (data['localidade'] != '' ? data['localidade'] : this.info.cidade),
                this.info.bairro =  (data['bairro'] != '' ? data['bairro'] : this.info.bairro),
                this.info.logradouro = (data['logradouro'] != '' ? data['logradouro'] : this.info.logradouro)

            } catch (error) {
                this.answer = 'Error! Could not reach the API. ' + error
            }
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('nextPage', {info: this.info});
            }
        },
        prevPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('prevPage', {info: this.info});
            }
        },
        validateForm() {
            if (this.info.utiliza_transporte == "1" && (this.info.qual_transporte == '' || this.info.qual_transporte == undefined))
                {this.validationErrors['qual_transporte'] = true;}
            else
                {delete this.validationErrors['qual_transporte'];}
            return !Object.keys(this.validationErrors).length;
            return true;
        }
    },
    async beforeMount() {

      console.log(this.dadosPessoa)

        this.info.nome =   (this.dadosPessoa.nome != undefined ? this.dadosPessoa.nome : this.info.nome);
        this.info.sobrenome = (this.dadosPessoa.sobrenome != undefined ? this.dadosPessoa.sobrenome : this.info.sobrenome);
        this.info.nomeMae = (this.dadosPessoa.nomeMae != undefined ? this.dadosPessoa.nomeMae : this.info.nomeMae);
        this.info.nomePai = this.dadosPessoa.nomePai;
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia =  this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.mediacao_escolar = this.dadosPessoa.mediacao_escolar;
        this.info.email =  this.dadosPessoa.email;
        this.info.emailPadrao =  this.dadosPessoa.emailPadrao;
        this.info.senha =  this.dadosPessoa.senha;
        this.info.confirmarSenha =  this.dadosPessoa.confirmarSenha;
        this.info.estadocivil =  (this.dadosPessoa.estadocivil != undefined ? this.dadosPessoa.estadocivil : this.info.estadocivil );
        this.info.certidaoNascimento =  this.dadosPessoa.certidaoNascimento;
        this.info.certidaoCasamento =  this.dadosPessoa.certidaoCasamento;
        this.info.rg =  this.dadosPessoa.rg;
        this.info.rgDataEmissao = (this.dadosPessoa.rgDataEmissao != undefined ? this.dadosPessoa.rgDataEmissao : this.info.rgDataEmissao);
        this.info.cpf =  this.dadosPessoa.cpf;
        this.info.cpfDele = this.dadosPessoa.cpfDele;
        this.info.dataNascimento =  (this.dadosPessoa.dataNascimento != undefined ? this.dadosPessoa.dataNascimento : this.info.dataNascimento);
        this.info.cep =  (this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep);
        this.info.estado =  this.dadosPessoa.estado;
        this.info.cidade =  this.dadosPessoa.cidade;
        this.info.bairro =  this.dadosPessoa.bairro;
        this.info.logradouro =  this.dadosPessoa.logradouro;
        this.info.numero =  this.dadosPessoa.numero;
        this.info.complemento =  this.dadosPessoa.complemento;
        this.info.telefone =  (this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone);
        this.info.sexo =  (this.dadosPessoa.sexo != undefined ? this.dadosPessoa.sexo : this.info.sexo );
        this.info.foto =  this.dadosPessoa.foto;
        this.cepInfo = this.info.cep;
        this.info.id =  (this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id);
        this.info.login_id =  (this.dadosPessoa.login_id != undefined ? this.dadosPessoa.login_id : this.info.login_id);
        this.dadosPessoa.jaBuscou = true;
        this.info.matricula = this.dadosPessoa.matricula;
        this.info.utiliza_transporte =  (this.dadosPessoa.utiliza_transporte != undefined ? this.dadosPessoa.utiliza_transporte : '0');

        this.info.qual_transporte =  (this.dadosPessoa.qual_transporte != undefined ? this.dadosPessoa.qual_transporte : false);
        this.info.alergico = (this.dadosPessoa.alergico != undefined ? this.dadosPessoa.alergico : this.info.alergico);
        this.info.tipo_alergia = (this.dadosPessoa.tipo_alergia != undefined ? this.dadosPessoa.tipo_alergia : this.info.tipo_alergia);
        this.info.nacionalidade = (this.dadosPessoa.nacionalidade != undefined ? this.dadosPessoa.nacionalidade : this.info.nacionalidade);
        this.info.naturalidade = (this.dadosPessoa.naturalidade != undefined ? this.dadosPessoa.naturalidade : this.info.naturalidade);
        this.info.cor = (this.dadosPessoa.cor != undefined ? this.dadosPessoa.cor : this.info.cor);
        this.info.religiao = (this.dadosPessoa.religiao != undefined ? this.dadosPessoa.religiao : this.info.religiao);
        this.info.aulas_religiao_frequentara = (this.dadosPessoa.aulas_religiao_frequentara != undefined ? this.dadosPessoa.aulas_religiao_frequentara : "0");
        this.info.usoDaImage = (this.dadosPessoa.usoDaImage != undefined ? this.dadosPessoa.usoDaImage : "0");
        this.info.responsavel = (this.dadosPessoa.responsavel != undefined ? this.dadosPessoa.responsavel : "");
        this.info.orgao_emissor = (this.dadosPessoa.orgao_emissor != undefined ? this.dadosPessoa.orgao_emissor : "");
        this.info.uf_rg = (this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "");
        this.info.livro_rg = (this.dadosPessoa.livro_rg != undefined ? this.dadosPessoa.livro_rg : "");
        this.info.termo_rg = (this.dadosPessoa.termo_rg != undefined ? this.dadosPessoa.termo_rg : "");

        this.info.nis = (this.dadosPessoa.nis != undefined ? this.dadosPessoa.nis : "");
        this.info.sus = (this.dadosPessoa.sus != undefined ? this.dadosPessoa.sus : "");

        this.info.folha_rg = (this.dadosPessoa.folha_rg != undefined ? this.dadosPessoa.folha_rg : "");
        this.info.conectividade = (this.dadosPessoa.conectividade != undefined ? this.dadosPessoa.conectividade :  "1");
        this.info.tipos_dispotivos = (this.dadosPessoa.tipos_dispotivos != undefined ? this.dadosPessoa.tipos_dispotivos : []);
        this.info.tipo_sanguineo = (this.dadosPessoa.tipo_sanguineo != undefined ? this.dadosPessoa.tipo_sanguineo : '');
        this.info.estadocivil = (this.dadosPessoa.estadocivil != undefined ? this.dadosPessoa.estadocivil : 'Solteiro');
        this.info.emailDele = this.dadosPessoa.emailDele;
        this.info.escola_id = this.dadosPessoa.escola_id;
        //this.info.utiliza_transporte = "0"
    },
}
</script>

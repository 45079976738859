import axios from 'axios'

export class buscarCEP {
    static async searchCep (cep) {
        let url_cep = 'https://viacep.com.br/ws/' + cep + '/json';
        let result = "";
        await fetch(url_cep,{
          method: 'GET',
          headers:{
             'Content-Type': 'application/json; charset=utf-8'
          }
        })
        .then(resposta =>resposta.json())
        .then(data =>  result = data)
        .catch(erro => result = erro);

        return result;
    }
}
